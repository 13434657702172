/* eslint-disable no-unused-vars */
import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { SinglePageQuery } from 'generated/types/gatsby'
import { Layout } from '../components/Layout'

const MDXRenderer = require('gatsby-plugin-mdx/mdx-renderer')
/* eslint-enbale no-unused-vars */

interface ContentTemplateProps {
	readonly data: SinglePageQuery
}

const ContentTemplate = ({ data }: ContentTemplateProps) => {
	const {
		mdx: { frontmatter, body },
	} = data

	return (
		<Layout date={frontmatter.date} path={frontmatter.path}>
			<Helmet title={`${frontmatter.title}`} />
			<h2>{frontmatter.title}</h2>
			<MDXRenderer>{body}</MDXRenderer>
		</Layout>
	)
}

export default ContentTemplate

export const query = graphql`
	query SinglePage($path: String!) {
		mdx(frontmatter: { draft: { ne: true }, path: { eq: $path } }) {
			body
			frontmatter {
				date(formatString: "MMMM D, YYYY")
				path
				title
			}
		}
	}
`
